@import url(https://fonts.googleapis.com/css?family=Montserrat);

$gold: #f0c322;
$body-background: $black;
$body-font-color: $white;
.top-bar, .top-bar ul {background-color:$black; color:$white;}

// Fond transparent
.off-canvas-content { background-color:transparent;}
.fi-mail {font-size:5rem;line-height:0;margin:0;padding:0;}

// Fond image
.banner {
	background: url('../../assets/img/promenade.jpg') no-repeat center center;
	background-size: cover;
	background-attachment: fixed;
	background-color:$black;
}
.logo {padding:0 0;}
h1 {
	font-size:2.5rem;
	@include breakpoint(small only)
	{
		font-size:1.5rem;
	}
}
.text-right { text-align:right;}
.text-left { text-align:left;}
.text-center { text-align:center;}
.telephone {font-size:1.8rem; color:$gold;}
// ================= FORMULAIRE ========================= //

.formulaire {
	border-radius:10px;
	padding-bottom:20px;
	padding-top:20px;
	background-color:rgba($light-gray,0.8);
}
.texte-noir { color:$black;}
.prev,.next,.dow,.today,.date-switch { color:$black;}
.fieldset {
	border-top: 1px solid $black;
	border-bottom: 1px solid $black;

}
legend {
	color:$black;
	background-color:transparent !important;
	margin-left:10px;
}
.alert { color:red;}
.soumission {
	color:$black;
	padding:15px 50px;
	font-size:16px;
	background: #f3d078;
	background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#f7dfa5),color-stop(100%,#f0c14b));
	background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
	background: -ms-linear-gradient(to bottom,#f7dfa5,#f0c14b);
	background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
	border-color: #cba957 #bf942a #aa8326;
	border-radius: .4rem;
}
.form-error {
	font-size:16px;
}
label {
	color:$black;
	font-weight:bold;
	@include breakpoint(small only)
	{
		text-align:left;
		//margin-top:10px;
	}
	@include breakpoint(medium)
	{
		font-size:130%;
		//margin-top:30px;
	}
}
input {
	height:3rem !important;
	border-radius:10px!important;
	
}
input[type=radio], input[type=checkbox] {
	display:none;
}
input[type=radio] + label:before {
	content: "";  
	display: inline-block;  
	width: 15px;  
	height: 15px;  
	vertical-align:middle;
	margin-right: 8px;  
	background-color: scale-color($medium-gray,$lightness:+100%);  
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, .3);
	border-radius: 8px;  
}
input[type=checkbox] + label:before {
	content: "";  
	display: inline-block;  
	width: 25px;  
	height: 25px;  
	vertical-align:middle;
	margin-right: 8px;
	margin-bottom:20px;
	margin-top:15px;
	background-color: scale-color($medium-gray,$lightness:+100%);  
	box-shadow: inset 0px 2px 2px rgba(0, 0, 0, .3);
	border-radius: 4px;  
}
input[type=radio]:checked + label:before {
	content: "\2022"; /* Bullet */
	color:$white;
	background-color:scale-color($success-color,$lightness:-30%); 
	font-size:1.8em;
	text-align:center;
	line-height:14px;
	text-shadow:0px 0px 3px $light-gray;
}
input[type=checkbox]:checked + label:before {
	content:"\2714"; /* Tick */
	color:$white;
	background-color: $dark-gray; 
	text-align:center;
	line-height:25px;
	text-shadow:0px 0px 3px $light-gray;
	font-size:200%;
}
.radiocenter{
	@include breakpoint(medium)
	{
		//text-align:left;
	}
}
#infos {
	display:none;
}
.disposition {
	border-radius:10px;
	margin-top:1rem;
	background-color:rgba(0,174,255,0.60);
}
.transfert {
	border-radius:10px;
	margin-top:1rem;
	background-color:rgba(0,152,54,0.60);
}
.menu a {
	color:#f0c322;
}
.white {
	color:rgba($white,0.9);
	
}
.title-bar{
	height:4rem;
	background:$black;
}
.title-bar-left,.title-bar-right{
	padding:0.6rem 0 1rem 0!important;
	font-size:1.3rem;
}
.menu-icon{
	@include hamburger($white, $dark-gray, 25px, 23px, 3px, 3);
}
.golden {
	color:$gold;
}